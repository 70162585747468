<template>
  <router-view />
</template>
<script>
import { useI18n } from 'vue-i18n'
import User from '@/store/User/'
// import { start } from '@/utils/noDebuger'
// import { useSocket } from '@/utils/useSocket'
// import { onMounted } from 'vue'
// import { useStore } from 'vuex'
export default {
  name: 'App',
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    // const { initSocket } = useSocket()
    // const store = useStore()

    // onMounted(() => {
    //   if (store.state.User.user) {
    //     store
    //       .dispatch('User/fetcChatListAction')
    //       .then(result => {
    //         console.log(
    //           'App.Vue',
    //           'User/fetcChatListAction => initSocket',
    //           result
    //         )
    //         initSocket()
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //   }
    // })
    return {
      t,
      locale
    }
  },
  computed: {},
  mounted() {
    console.log(this.$t('msg'))
    console.log(this.locale)
    // 禁止非法操作，觀看原始碼
    // start()
  },
  beforeMount() {
    // 註冊模塊
    this.$store.registerModule('User', User)
  },
  watch: {
    $route: function() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/socialv.scss';
@import url('./plugins/icons/remixicon/fonts/remixicon.css');
@import url('./plugins/icons/line-awesome/dist/line-awesome/css/line-awesome.min.css');
@import url('./plugins/icons/@fortawesome/fontawesome-free/css/all.min.css');
@import url('./plugins/Leaflet/leaflet.css');
@import url('./plugins/fullcalendar/core/main.css');
@import url('./plugins/fullcalendar/daygrid/main.css');
@import url('./plugins/fullcalendar/timegrid/main.css');
@import url('./plugins/fullcalendar/list/main.css');
</style>
