export default {
  SET_USER (state, data) {
    state.user = data
  },
  SET_TOKEN (state, data) {
    state.token = data
  },
  SET_IS_PLAN (state, data) {
    state.isPlan = data
  },
  SET_PLAN_DATE (state, data) {
    state.planDate = data
  },
  SET_DIALOG_PAY (state, data) {
    state.dialogPayVisible = data
  },
  SET_DIALOG_UNLOCK_PAY(state, data) {
    state.dialogUnlockVisible = data;
  },
  SET_DIALOG_TITLE (state, data) {
    state.dialogPayTitle = data
  },
  SET_DIALOG_TIP_PAY (state, data) {
    state.dialogTipPayVisible = data
  },
  SET_DIALOG_ITEM (state, data) {
    state.dialogItem = data
  },
  SET_DIALOG_POST_ITEM (state, data) {
    state.dialogPostItem = data
  },
  SET_CHAT_LIST (state, data) {
    state.chatList = data
  },
  SET_GROUP_CHAT_LIST (state, data) {
    state.groupChatList = data
  },
  SET_CHAT_OBJECT (state, data) {
    state.currentChat = data
  },
  SET_EDIT_POST (state, data) {
    state.dialogEditPostVisible = data
  },
  SET_EDIT_POST_ID (state, data) {
    state.dialogEditPostId = data
  },
  SET_CHAT_UNREAD_NUM(state, data) {
    state.getUnreadNum = data;
  }
}
